import { Link } from "gatsby"
import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { globalHistory } from "@reach/router"
import Icon from '../Utilities/Icon'

import "./Nav.css"

class NavLinkDropdown extends React.Component {

  state = {
    subMenuActive: false,
    hoverSubMenuActive: false,
    x: 0,
    y: 0,
  };

  toggleSubMenu = () => {
    this.setState(state => ({ subMenuActive: !state.subMenuActive }));
  };

  subMenuFalse = () => {
    this.setState({subMenuActive: false });
  };

  // hoverSubMenuTrue = (event) => {
  //   event.target.classList.contains('hover-ready') ? this.setState({hoverSubMenuActive: true }) : this.setState({hoverSubMenuActive: false });
  // };

  // hoverSubMenuFalse = () => {
  //   this.setState({hoverSubMenuActive: false });
  // };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.handleScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    this.handleScroll()
  }

  handleScroll = ev => {
    this.setState({
      x: window.scrollX,
      y: window.scrollY
    });
  };

  render () {

    const path = globalHistory.location.pathname;

    const parentPath = this.props.parentPath || this.props.linkTo
  
    return (

      <li className={`nav__li--has-children ${this.state.subMenuActive ? 'sub-menu-active' : ''}`}>

        <Link to={this.props.linkTo} activeClassName="active" className={`parent-link ${path.includes(parentPath) ? 'active' : ''} ${this.state.subMenuActive ? 'sub-menu-active' : ''}`}>
          {this.props.linkText}
        </Link>
        <button
          id={`ToggleSubMenu-${this.props.linkTo.replace(/\//g, '')}`}
          aria-label="Toggle Sub Menu"
          className={`parent-btn ${path.includes(parentPath) ? 'active' : ''} ${this.state.subMenuActive ? 'sub-menu-active' : ''}`}
          onClick={this.toggleSubMenu}
          onKeyDown={this.toggleSubMenu}
        >
          <span>{this.props.linkText}</span>
          <span className={`nav__sub-arrow`}>
            {this.state.subMenuActive ? <Icon iconName="icon-angle-up" /> : <Icon iconName="icon-angle-down" /> }
          </span>
        </button>

        <ul
          className={`
            nav__sub-menu nav__sub-menu--horz flex ${this.props.subnavClass}
            ${this.state.subMenuActive ? 'active' : ''}
            ${path.includes(parentPath) || (path === "/" && parentPath === '/club-stats') ? 'nav__sub-menu--pin-ready' : ''}
            ${this.state.y > 150 && path.includes(parentPath) || (path === "/" && parentPath === '/club-stats') ? 'nav__sub-menu--pinned flex' : ''}
            ${path === this.props.linkTo && this.props.highlightFirstChildOnParent ? 'highlight-first' : ''}
          `}
        >

          {parentPath === "/international-stats/" ? (
            <>
            <li><Link to={this.props.linkTo} activeClassName="active">All Time</Link></li>
            <li><Link to="/international-stats/world-cup-stats/" activeClassName="active">World Cup Stats</Link></li>
            <li><Link to="/international-stats/copa-euros-stats/" activeClassName="active">Copa/Euros Stats</Link></li>
            <li><Link to="/international-stats/fifa-rankings/" activeClassName="active">Stats by FIFA Ranking</Link></li>
            </>
          ) : ''}

          {parentPath === "/all-time-stats/" ? (
            <>
            <li><Link to="/" activeClassName="active">All Time Career Stats</Link></li>
            <li><Link to="/detailed-stats/" activeClassName="active">Detailed Stats</Link></li>
            <li><Link to="/club-stats/champions-league/" activeClassName="active">Champions League</Link></li>
            <li><Link to="/club-stats/messi-vs-ronaldo-in-spain/" activeClassName="active">Messi vs Ronaldo in Spain</Link></li>
            <li><Link to="/club-stats/el-clasico/" activeClassName="active">El Clasico</Link></li>
            <li><Link to="/detailed-stats/record-in-finals/" activeClassName="active">Record in Finals</Link></li>
            <li><Link to="/all-time-stats/league-rankings-top/" activeClassName="active">In Top X Leagues</Link></li>
            <li><Link to="/all-time-stats/vs-top-sides/" activeClassName="active">vs Top 3, 5, 10 etc.</Link></li>
            <li><Link to="/all-time-stats/home-away-stats/" activeClassName="active">Home &amp; Away Stats</Link></li>
            <li><Link to="/all-time-stats/games-vs-each-other/" activeClassName="active">Games vs Each Other</Link></li>
            <li><Link to="/all-time-stats/stats-by-age/" activeClassName="active">Stats by Age</Link></li>
            <li><Link to="/all-time-stats/ronaldo-al-nassr-stats/" activeClassName="active">Ronaldo Stats by Club</Link></li>
            <li><Link to="/all-time-stats/messi-inter-miami-stats/" activeClassName="active">Messi Stats by Club</Link></li>
            <li><Link to="/all-time-stats/favourite-opponents/" activeClassName="active">Favourite Opponents</Link></li>
            <li><Link to="/all-time-stats/vs-english-teams/" activeClassName="active">vs English Teams etc.</Link></li>
            <li><Link to="/all-time-stats/uefa-club-rankings/" activeClassName="active">Stats by UEFA Coefficient</Link></li>
            <li><Link to="/detailed-stats/all-goals-including-youth/" activeClassName="active">All Goals Including Youth</Link></li>
            </>
          ) : ''}

          {parentPath === "/detailed-stats/" ? (
            <>
            <li><Link to="/detailed-stats/" activeClassName="active">All Detailed Stats</Link></li>
            <li><Link to="/detailed-stats/free-kicks/" activeClassName="active">Free Kicks</Link></li>
            <li><Link to="/detailed-stats/hat-tricks/" activeClassName="active">Hat Tricks</Link></li>
            <li><Link to="/detailed-stats/penalties/" activeClassName="active">Penalties</Link></li>
            <li><Link to="/detailed-stats/goals/" activeClassName="active">Goals</Link></li>
            <li><Link to="/detailed-stats/goals-without-penalties/" activeClassName="active">Goals Without Penalties</Link></li>
            <li><Link to="/detailed-stats/goal-contributions/" activeClassName="active">Goal Contributions</Link></li>
            <li><Link to="/detailed-stats/opta-assists/" activeClassName="active">Opta Assists</Link></li>
            <li><Link to="/detailed-stats/all-assists/" activeClassName="active">All Assists (+ Non-Opta)</Link></li>
            <li><Link to="/detailed-stats/outside-box-goals/" activeClassName="active">Outside the Box Goals</Link></li>
            <li><Link to="/detailed-stats/shot-conversion/" activeClassName="active">Shot Conversion</Link></li>
            <li><Link to="/detailed-stats/xg-expected-goals/" activeClassName="active">xG (Expected Goals)</Link></li>
            <li><Link to="/detailed-stats/xa-expected-assists/" activeClassName="active">xA (Expected Assists)</Link></li>
            <li><Link to="/detailed-stats/key-passes/" activeClassName="active">Key Passes</Link></li>
            <li><Link to="/detailed-stats/big-chances-created/" activeClassName="active">Big Chances Created</Link></li>
            <li><Link to="/detailed-stats/throughballs/" activeClassName="active">Throughballs</Link></li>
            <li><Link to="/detailed-stats/successful-dribbles/" activeClassName="active">Successful Dribbles</Link></li>
            <li><Link to="/detailed-stats/aerial-duels/" activeClassName="active">Aerial Duels</Link></li>
            <li><Link to="/detailed-stats/man-of-the-match-awards/" activeClassName="active">Man of the Match</Link></li>
            <li><Link to="/detailed-stats/average-match-ratings/" activeClassName="active">Average Match Ratings</Link></li>
            <li><Link to="/detailed-stats/match-winning-goals/" activeClassName="active">Match Winning Goals</Link></li>
            <li><Link to="/detailed-stats/points-contribution/" activeClassName="active">Points Contribution</Link></li>
            <li><Link to="/detailed-stats/defensive-stats/" activeClassName="active">Defensive Stats</Link></li>
            </>
          ) : ''}

          {parentPath === "/match-histories/" ? (
            <>
            <li><Link to="/match-histories/messi-match-history/" activeClassName="active">Messi Match History</Link></li>
            <li><Link to="/match-histories/ronaldo-match-history/" activeClassName="active">Ronaldo Match History</Link></li>
            </>
          ) : ''}

          {parentPath === "/honours-and-achievements/" ? (
            <>
            <li><Link to="/honours-and-achievements/" activeClassName="active">Team Trophies</Link></li>
            <li><Link to="/individual-awards/" activeClassName="active">Individual Awards</Link></li>
            </>
          ) : ''}

          {/* {parentPath === "/articles/" ? (
            <>
            <li><Link to="/articles/messi-position-role-evolution/" activeClassName="active">Messi Positional Evolution</Link></li>
            <li><Link to="/articles/ronaldo-position-role-evolution/" activeClassName="active">Ronaldo Positional Evolution</Link></li>
            </>
          ) : ''} */}

          {parentPath === "/info/" ? (
            <>
            <li><Link to="/info/" activeClassName="active">Info</Link></li>
            <li><Link to="/faqs/" activeClassName="active">FAQs</Link></li>
            </>
          ) : ''}

          {this.props.site.allSitePage.edges
          .filter(link => (link.node.path.includes(parentPath)))
          .filter(link => link.node.path !== parentPath)
          .filter(link => link.node.path !== '/club-stats/messi-vs-ronaldo-in-spain/')
          .filter(link => link.node.path !== '/club-stats/champions-league/')
          .filter(link => link.node.path !== '/club-stats/el-clasico/')
          .filter(link => link.node.path.includes('/detailed-stats/') === false)
          .filter(link => link.node.path.includes('/match-histories/') === false)
          .filter(link => link.node.path.includes('/all-time-stats/') === false)
          .filter(link => link.node.path.includes('/articles/') === false)
          .filter(link => link.node.path.includes('/world-cup-stats/') === false)
          .filter(link => link.node.path.includes('/copa-euros-stats/') === false)
          .filter(link => link.node.path.includes('/fifa-rankings/') === false)
          .filter(link => link.node.path.includes('/fifa-rankings-competitive/') === false)
          .map(link => (
            <li key={link.node.path}>
              <Link to={link.node.path.endsWith('/') ? link.node.path : link.node.path+'/'} activeClassName="active">

                {/* {parentPath === "/club-stats/" && link.node.path !== "/club-stats/messi-vs-ronaldo-in-spain/" && link.node.path !== "/club-stats/el-clasico/" && link.node.path !== "/club-stats/champions-league/" ? link.node.context.slug.replace(/-/g, '/')
                : parentPath === "/club-stats/" ? link.node.path.replace(/club-stats/g, '').replace(/-/g, ' ').replace(/\//g, '')
                : parentPath === "/detailed-stats/" ? link.node.path.replace(/detailed-stats/g, '').replace(/-/g, ' ').replace(/\//g, '')
                : link.node.context.slug } */}

                {parentPath === "/club-stats/" ? link.node.context.slug.replace(/-/g, '/')
                : parentPath === "/detailed-stats/" ? link.node.path.replace(/detailed-stats/g, '').replace(/-/g, ' ').replace(/\//g, '')
                : link.node.context.slug }

              </Link>
            </li>
          ))}
        </ul>

      </li>

    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query NavQuery {
        allSitePage {
          edges {
            node {
              path
              context {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <NavLinkDropdown site={data} {...props} />
    )}
  />
)

